import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    formatShortDate,
    getRelativeLabelFromRelativeValue,
} from '../../../services/helpers/dateTimeFormats';
import useModal from '../../../services/hooks/useModal';
import Button from '../Button/Button';
import SearchFilterButton from '../Button/SearchFilterButton';
import FilterDropdown from '../Dropdown/FilterDropdown';
import RelativeRange from '../CalendarDateRange/RelativeRange';
import RelativeOperationTypes from '../../../types/RelativeOperation';
import { getInitialRelativeState } from '../../../services/helpers/filterHelpers';
import FilterDateType from '../../../types/filter/FilterDateType';
import Tabs from '../Tabs/Tabs';
import Flex from '../Box/Flex';
import DateRangePicker from '../CalendarDateRange/DateRangePicker';

interface DateRangeProps {
    onDateChange: (termkey: string | undefined, filter: string[]) => void;
    initialValue: string[];
    maxDate?: Date;
    minDate?: Date;
    dateRangeType: string | undefined;
    label: string;
    relativeRanges?: { name: string; label: string; value: string }[];
    relativeLabel?: { type: string; operation: RelativeOperationTypes };
    clearFilters?: (type: string) => void;
    position?: string;
}

const DateRange: React.FC<DateRangeProps> = ({
    onDateChange,
    initialValue,
    maxDate,
    minDate,
    dateRangeType,
    label,
    relativeRanges,
    relativeLabel,
    clearFilters,
    position,
}: DateRangeProps) => {
    const initialTabState =
        initialValue.length === 1 ? FilterDateType.Relative : FilterDateType.Absolute;

    const { isShown, toggle } = useModal();

    const [absoluteChoice, setAbsoluteChoice] = useState(
        initialValue.length === 2 ? initialValue : ['', '']
    );
    const [relativeChoice, setRelativeChoice] = useState(
        initialValue.length === 1 ? initialValue : ['']
    );

    const onClear = () => {
        if (absoluteChoice[0] || absoluteChoice[1] || relativeChoice[0]) {
            if (clearFilters) {
                clearFilters(label);
            } else {
                onDateChange(dateRangeType, []);
            }
        }

        setAbsoluteChoice(['', '']);
        setRelativeChoice(['']);

        if (isShown) {
            toggle();
        }
    };

    const returnDateStatus = () => {
        if (relativeChoice[0] !== '')
            return getRelativeLabelFromRelativeValue(
                relativeLabel ? relativeLabel.type : '',
                relativeChoice[0]
            );

        const [from, to] = absoluteChoice;

        if (to === '' && from === '') return 'All';

        if (from !== '' && to === '' && initialValue.length !== 0) {
            return `from ${formatShortDate(new Date(from))}`;
        }

        if (from !== '' && to !== '' && initialValue.length !== 0) {
            return `${formatShortDate(new Date(from))} - ${formatShortDate(new Date(to))}`;
        }

        if (from === '' && to !== '' && initialValue.length !== 0) {
            return `until ${formatShortDate(new Date(to))}`;
        }

        return 'All';
    };

    const handleApplyRelativeRange = () => {
        onDateChange(dateRangeType, [relativeChoice[0]]);
        toggle();
    };

    const handleApply = (filter: string[]) => {
        onDateChange(dateRangeType, filter);
        setRelativeChoice(['']);
        if (isShown) {
            toggle();
        }
    };

    const disableApplyRelative = /((\+|\-|)0((?![d])[a-z]))/.test(relativeChoice[0]);
    const disableApplyAbsolute =
        (absoluteChoice[0] === '' && absoluteChoice[1] === '' && initialValue.length === 0) ||
        (absoluteChoice[0] === initialValue[0] && absoluteChoice[1] === initialValue[1]);

    useEffect(() => {
        if (initialValue.length === 1 && relativeChoice[0] !== initialValue[1])
            setRelativeChoice(initialValue);

        if (
            initialValue.length == 2 &&
            absoluteChoice[0] !== initialValue[0] &&
            absoluteChoice[1] !== initialValue[1]
        )
            setAbsoluteChoice(initialValue);
    }, [initialValue]);

    return (
        <FilterDropdown
            position={position}
            toggle={toggle}
            isShown={isShown}
            initiator={
                <SearchFilterButton
                    modalToggled={isShown}
                    onClick={toggle}
                    filterExists={initialValue.length !== 0}
                    onClear={onClear}
                    criteria={label}
                >
                    {returnDateStatus()}
                </SearchFilterButton>
            }
        >
            <DatePickContainer>
                <Tabs
                    tabsList={[
                        {
                            key: FilterDateType.Relative,
                            text: 'Relative range',
                            isVisible: !!(relativeRanges && relativeLabel),
                        },
                        { key: FilterDateType.Absolute, text: 'Absolute range' },
                    ]}
                    startTab={initialTabState}
                >
                    <Flex grow={1} column key={FilterDateType.Absolute}>
                        <DateRangePicker
                            onChange={({ start, end }) => setAbsoluteChoice([start, end])}
                            onEnter={() => handleApply(absoluteChoice)}
                            isOpen={isShown}
                            periodValueTo={absoluteChoice[1]}
                            periodValueFrom={absoluteChoice[0]}
                            minDate={minDate}
                            maxDate={maxDate}
                            height="100%"
                        />
                        <Buttons>
                            <StyledButton
                                id="apply-aboslute-range"
                                disabled={disableApplyAbsolute}
                                onClick={() => handleApply(absoluteChoice)}
                                type="button"
                                primary
                                large
                            >
                                Apply
                            </StyledButton>
                        </Buttons>
                    </Flex>
                    {relativeRanges && relativeLabel && (
                        <Flex grow={1} column key={FilterDateType.Relative}>
                            <RelativeRange
                                relativeChoice={getInitialRelativeState(
                                    relativeChoice,
                                    label,
                                    relativeRanges,
                                    relativeLabel ? relativeLabel.type : undefined
                                )}
                                setRelativeChoice={x => setRelativeChoice([x.relative])}
                                ranges={relativeRanges}
                                labelType={label}
                                relativeLabel={relativeLabel}
                                onEnter={handleApplyRelativeRange}
                            />
                            <Buttons>
                                <StyledButton
                                    id="apply-relative-range"
                                    type="submit"
                                    onClick={handleApplyRelativeRange}
                                    disabled={disableApplyRelative}
                                    primary
                                    large
                                >
                                    Apply
                                </StyledButton>
                            </Buttons>
                        </Flex>
                    )}
                </Tabs>
            </DatePickContainer>
        </FilterDropdown>
    );
};



const DatePickContainer = styled.div`
    background: ${props => props.theme.colors.light};
    width: 33rem;
    height: 54rem;
    display: flex;
    flex-direction: column;
`;

const Buttons = styled.div`
    margin-top: auto;
    padding: 1rem;
    border-top: 0.1rem solid ${props => props.theme.colors.border};
`;

const StyledButton = styled(Button)`
    min-width: 14rem;
    width: 100%;
`;
export default DateRange;
