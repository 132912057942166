import React from 'react';
import styled from 'styled-components';

interface InputProps {
    setRef?: (instance: HTMLInputElement | null) => void;
    validationError?: boolean;
    focus?: boolean;
    background?: boolean;
    dataTestId?: string;
}
type InputType = InputProps &
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const TextArea: React.FC<InputType> = ({
    disabled,
    name,
    id,
    placeholder,
    onChange,
    autoComplete = 'off',
    value,
    defaultValue,
    dataTestId,
}: InputType) => {
    const onKeyDownPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.code === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            const target = event.target as HTMLTextAreaElement;

            target.form?.requestSubmit();
        }
    };

    return (
        <StyledTextArea
            data-testid={dataTestId}
            maxLength={100}
            onChange={onChange}
            onKeyDown={onKeyDownPress}
            disabled={disabled}
            name={name}
            autoComplete={autoComplete}
            value={value}
            defaultValue={defaultValue}
            id={id}
            placeholder={placeholder}
        />
    );
};

const StyledTextArea = styled.textarea`
    max-width: -webkit-fill-available;
    min-width: -webkit-fill-available;
    min-height: 5rem;
    max-height: 6rem;
    padding: 0.5rem 0 0 0.5rem;
    background-color: ${props => props.theme.colors.medium};
    border: none;
`;


export default TextArea;
