import React, { useEffect, useRef, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FormRow from '../Form/FormRow';
import GridInput from '../Input/GridInput';
import CalendarHeader from './CalendarHeader';
import WeekDays from './WeekDays';
import Days from './Days';
import {
    DateRangeActions,
    DateRangeConstants,
    DateRangeState,
    isValidDateString,
} from '../DateRange/DateRangeStateHelper';
import { formatShortDateNumerical } from '../../../services/helpers/dateTimeFormats';
import { CalendarState } from './CalendarState';
import { getDaysInMonth } from '../../../services/helpers/dateTimeHelper';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

interface CalendarProps {
    currentState: DateRangeState;
    maxDate?: Date;
    minDate?: Date;
    actionDispatch: (value: DateRangeActions) => void;
    onEnter: () => void;
    label: string;
    theme: DefaultTheme;
}

const Calendar: React.FC<CalendarProps> = ({
    currentState,
    maxDate,
    minDate,
    actionDispatch,
    onEnter,
    label,
    theme,
}: CalendarProps) => {
    const now = new Date();

    const [calendarState, setCalendarState] = useState<CalendarState>({
        month:
            currentState.currentMonth || currentState.currentMonth == 0
                ? currentState.currentMonth
                : now.getMonth(),
        year: currentState.currentYear ? currentState.currentYear : now.getFullYear(),
    });

    const {
        FROM_DATE,
        TO_DATE,
        RESET_FROM,
        RESET_TO,
        SET_MONTH,
        FOCUS_CHANGE,
        FROM_CHANGE,
        TO_CHANGE,
    } = DateRangeConstants;
    const { from, to } = currentState;
    const [fromDate, setFromDate] = useState(from);
    const [toDate, setToDate] = useState(to);
    const [validationError, setValidationError] = useState({
        FROM: '',
        TO: '',
    });

    const fromDateRef = useRef<HTMLInputElement>(null);
    const toDateRef = useRef<HTMLInputElement>(null);

    const setCalendar = (value: Partial<CalendarState>) => {
        setCalendarState({ ...calendarState, ...value });
    };

    const setDateRange = ([selectedFrom, selectedTo]: (Date | null)[]) => {
        const dispatchChange = (
            type: DateRangeConstants.FROM_CHANGE | DateRangeConstants.TO_CHANGE,
            value: Date | null
        ) =>
            actionDispatch({
                type,
                payload: value ? formatShortDateNumerical(value) : '',
            });

        if (selectedFrom !== currentState.fromDate) {
            dispatchChange(FROM_CHANGE, selectedFrom);
        }

        if (selectedTo !== currentState.toDate) {
            dispatchChange(TO_CHANGE, selectedTo);
        }
    };

    const setCurrentMonth = (month: number, year: number) => {
        actionDispatch({ type: SET_MONTH, payload: { month, year } });
    };

    const setFocusInput = (focus: string) => {
        if (!focus || currentState.focusedInput === focus) return;

        actionDispatch({ type: FOCUS_CHANGE, payload: focus });
    };

    const onEmptyInputs = () => {
        if (currentState.focusedInput === FROM_DATE) {
            actionDispatch({ type: RESET_FROM });
        } else if (currentState.focusedInput === TO_DATE) {
            actionDispatch({ type: RESET_TO });
        }
    };

    const clearDate = (type: DateRangeConstants) => {
        if (type === FROM_DATE) {
            actionDispatch({ type: RESET_FROM });

            setFromDate('');
        } else {
            actionDispatch({ type: RESET_TO });

            setToDate('');
        }

        setValidationError(
            currentState.focusedInput === FROM_DATE
                ? { FROM: '', TO: validationError.TO }
                : { FROM: validationError.FROM, TO: '' }
        );
    };

    const getOnchangeValue = (currentValue: string, onchangeValue: string, latestInput: string) => {
        if (onchangeValue === '') {
            onEmptyInputs();
        }

        if (latestInput === ' ' || Number.isNaN(Number(latestInput))) return currentValue;

        const shouldAddDash =
            (currentValue.length === 4 && onchangeValue.length > currentValue.length) ||
            (currentValue.length === 7 && onchangeValue.length > currentValue.length);

        return shouldAddDash ? `${currentValue}-${latestInput}` : onchangeValue;
    };

    const handleDateInput = (
        value: string,
        field: DateRangeConstants,
        setValue: (value: string) => void,
        currentValue: string
    ) => {
        const validDate = isValidDateString(value);
        const valueAsDate = new Date(value);

        if (value.length === 0) {
            setValidationError(
                currentState.focusedInput === FROM_DATE
                    ? { FROM: '', TO: validationError.TO }
                    : { FROM: validationError.FROM, TO: '' }
            );
        }

        setValue(getOnchangeValue(currentValue, value, value.substring(currentValue.length)));

        if (!validDate && value.length === 10) {
            setValidationError(
                currentState.focusedInput === FROM_DATE
                    ? { FROM: 'Invalid date', TO: validationError.TO }
                    : { FROM: validationError.FROM, TO: 'Invalid date' }
            );
        }

        if (!validDate) return;

        if (
            valueAsDate.getMonth() !== calendarState.month ||
            valueAsDate.getFullYear() !== calendarState.year
        ) {
            setCalendarState({
                ...calendarState,
                month: valueAsDate.getMonth(),
                year: valueAsDate.getFullYear(),
            });
        }

        const greaterThanMaxDate =
            maxDate && valueAsDate.getTime() > maxDate?.getTime() && validDate;
        if (greaterThanMaxDate) {
            setValidationError(
                field === FROM_DATE
                    ? {
                          FROM: `Exceeds ${formatShortDateNumerical(maxDate)}`,
                          TO: validationError.TO,
                      }
                    : {
                          FROM: validationError.FROM,
                          TO: `Exceeds ${formatShortDateNumerical(maxDate)}`,
                      }
            );
            return;
        }

        const lessThanMinDate = minDate && valueAsDate.getTime() < minDate?.getTime() && validDate;
        if (lessThanMinDate) {
            setValidationError(
                field === FROM_DATE
                    ? { FROM: `Below ${formatShortDateNumerical(minDate)}`, TO: validationError.TO }
                    : {
                          FROM: validationError.FROM,
                          TO: `Below ${formatShortDateNumerical(minDate)}`,
                      }
            );
            return;
        }

        setValidationError({ FROM: '', TO: '' });

        const selectRange = ([f, t]: string[]) => {
            const selectedFrom = f ? new Date(f) : null;
            const selectedTo = t ? new Date(t) : null;

            if (selectedFrom && selectedTo && selectedFrom > selectedTo) {
                if (field === FROM_DATE) return [selectedFrom, null];
                if (field === TO_DATE) return [null, selectedTo];
            }

            return [selectedFrom, selectedTo];
        };

        const v = maxDate && greaterThanMaxDate ? formatShortDateNumerical(maxDate) : value;
        const range = selectRange(field === FROM_DATE ? [v, to] : [from, v]);

        setDateRange(range);
    };

    const selectDay = (date: string) => {
        const field = currentState.focusedInput === FROM_DATE ? FROM_DATE : TO_DATE;
        const setValue = currentState.focusedInput === FROM_DATE ? setFromDate : setToDate;
        const currentValue =
            currentState.focusedInput === FROM_DATE ? currentState.from : currentState.to;

        handleDateInput(date, field, setValue, currentValue);
        setFocusInput(currentState.focusedInput === FROM_DATE ? TO_DATE : FROM_DATE);
    };

    const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnter();
        }
    };

    const setShowCurrentYearMonth = (date: Date) => {
        if (
            !isNaN(date.getTime()) &&
            (currentState.currentYear !== date.getFullYear() ||
                currentState.currentMonth !== date.getMonth())
        ) {
            setCurrentMonth(date.getMonth(), date.getFullYear());
            setCalendarState({
                ...calendarState,
                month: date.getMonth(),
                year: date.getFullYear(),
            });
        }
    };

    useEffect(() => {
        if (currentState.focusedInput === FROM_DATE && currentState.fromSet) {
            setShowCurrentYearMonth(currentState.fromDate);
        } else if (currentState.focusedInput === TO_DATE && currentState.toSet) {
            setShowCurrentYearMonth(currentState.toDate);
        }
    }, [currentState.focusedInput]);

    useEffect(() => {
        const { from: selectedFrom, to: selectedTo } = currentState;
        if (selectedFrom || selectedTo) {
            setShowCurrentYearMonth(selectedFrom ? new Date(selectedFrom) : new Date(selectedTo));
        }
    }, []);

    useEffect(() => {
        setFromDate(f => (from !== f ? from : f));
        setToDate(t => (to !== t ? to : t));
    }, [from, to]);

    const fromDateShow =
        fromDate.includes('d') || fromDate.includes('w') || fromDate.includes('m') ? '' : fromDate;

    return (
        <Grid>
            <FormRow first last>
                <GridInput
                    setRef={fromDateRef}
                    dataTestId="calendar-input-from"
                    id={`${label}_from`}
                    type="text"
                    name={`${label}_from`}
                    value={fromDateShow}
                    onClick={() => setFocusInput(FROM_DATE)}
                    onFocus={() => setFocusInput(FROM_DATE)}
                    onChange={e =>
                        handleDateInput(e.currentTarget.value, FROM_DATE, setFromDate, fromDate)
                    }
                    onKeyDown={e => keyDown(e)}
                    placeholder="From yyyy-mm-dd"
                    maxLength={10}
                    placeholderRaised
                    autoComplete="off"
                    isFocused={currentState.focusedInput.includes(FROM_DATE)}
                    cursor="pointer"
                    validationErrorText={validationError.FROM}
                    decorator={
                        fromDateShow ? (
                            <ClearValueButton
                                data-testid="clear-from-value-button"
                                onClick={e => {
                                    e.preventDefault();
                                    clearDate(FROM_DATE);

                                    fromDateRef.current?.focus();
                                }}
                            >
                                <CloseLineIcon size={theme.icon.size.small} />
                            </ClearValueButton>
                        ) : (
                            <></>
                        )
                    }
                />

                <GridInput
                    setRef={toDateRef}
                    dataTestId="calendar-input-to"
                    id={`${label}_to`}
                    name={`${label}_to`}
                    type="text"
                    value={toDate}
                    onClick={() => setFocusInput(TO_DATE)}
                    onFocus={() => setFocusInput(TO_DATE)}
                    onChange={e =>
                        handleDateInput(e.currentTarget.value, TO_DATE, setToDate, toDate)
                    }
                    onKeyDown={e => keyDown(e)}
                    placeholder="To yyyy-mm-dd"
                    maxLength={10}
                    placeholderRaised
                    isFocused={currentState.focusedInput.includes(TO_DATE)}
                    cursor="pointer"
                    validationErrorText={validationError.TO}
                    decorator={
                        toDate ? (
                            <ClearValueButton
                                data-testid="clear-to-value-button"
                                onClick={e => {
                                    e.preventDefault();
                                    clearDate(TO_DATE);

                                    toDateRef.current?.focus();
                                }}
                            >
                                <CloseLineIcon size={theme.icon.size.small} />
                            </ClearValueButton>
                        ) : (
                            <></>
                        )
                    }
                />
            </FormRow>
            <CalendarContainer>
                <CalendarHeader
                    month={calendarState.month}
                    year={calendarState.year}
                    setCalendarState={setCalendar}
                    setCurrentMonth={setCurrentMonth}
                />
                <WeekDays />

                {React.useMemo(
                    () => (
                        <Days
                            days={getDaysInMonth(calendarState.month, calendarState.year)}
                            month={calendarState.month}
                            year={calendarState.year}
                            selectDay={selectDay}
                            range={[currentState.from, currentState.to]}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    ),
                    [calendarState, currentState, maxDate]
                )}
            </CalendarContainer>
        </Grid>
    );
};



const CalendarContainer = styled.div`
    overflow: hidden;
`;

const Grid = styled.div`
    padding: ${props => props.theme.layout.margin.small};
`;

const ClearValueButton = styled.button`
    color: ${props => props.theme.colors.primary};
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
        border: 0.2rem solid ${props => props.theme.colors.primary};
        border-radius: 2rem;
        outline: none;
    }
`;

export default withTheme(Calendar) as React.ComponentType<Omit<CalendarProps, 'theme'>>;
