import styled from 'styled-components';
import React, { ReactNode, useRef } from 'react';
import useOnClickOutside from './useOnClickOutside';

interface FilterDropdownProps {
    isShown: boolean;
    toggle: () => void;
    children: ReactNode;
    initiator: ReactNode;
    position?: string;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
    isShown,
    toggle,
    children,
    initiator,
    position,
}: FilterDropdownProps) => {
    const ref = useRef(null);
    const handleClickOutside = () => {
        if (isShown) {
            toggle();
        }
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <Container ref={ref}>
            {initiator}
            <DropdownContainer isShown={isShown} position={position}>
                {children}
            </DropdownContainer>
        </Container>
    );
};

interface DropdownContainerProps {
    isShown: boolean;
    position: string | undefined;
}

const Container = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div<DropdownContainerProps>`
    background: ${props => props.theme.colors.light};
    box-shadow: 0 0.06rem 0.19rem rgba(104, 104, 104, 0.12), 0 0.06rem 0.19rem rgba(0, 0, 0, 0.24);
    margin-top: ${props => props.theme.layout.margin.small};
    display: ${props => (props.isShown ? 'block' : 'none')};
    position: absolute;
    width: fit-content;
    z-index: 3;
    border-radius: 0.3rem;

    ${props =>
        props.position &&
        props.position === 'center' &&
        `
        right: auto;
        text-align: center;
        transform: translate(-45%, 0);
    `}

    ${props =>
        props.position &&
        props.position === 'right' &&
        `
        right: 0;
    `}
`;

export default FilterDropdown;


