/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled, { StyleSheetManager, ThemeProvider } from 'styled-components';
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import { createHistory, createMemorySource, LocationProvider } from '@reach/router';
import { walleyLightTheme } from '../theme/themes';
import WalleyGlobalStyles from '../theme/globalStyles';
import routes from '../routes';
import InviteLandingPage from './InviteLandingPage/InviteLandingPage';
import { config, ExtendedWindow } from '../config';
import UserNotFound from './UserNotFound';
import { useAppState } from '../store/appstate';
import checkForNewVersion from '../services/helpers/checkForNewVersion';
import Button from '../components/atoms/Button/Button';
import {
    setNewVersionAvailable,
    setUserFlow,
    UserSettingsState,
} from '../store/userSettings/userSettingsSlice';
import { SessionState, showProcessingNotComplete } from '../store/session/sessionSlice';
import NavBar from '../components/molecules/Navbar/Navbar';
import RefreshMessage from '../components/atoms/Message/RefreshMessage';
import { LockedModal } from '../components/atoms/Modal/LockedModal';
import LoadingScreen from './LoadingScreen';
import { useAppDispatch } from '../store';
import UserWithoutPermission from './UserWithoutPermission';
import Survey from '../components/molecules/Ces/Survey';
import surveyActivatedOn from '../services/surveyActivatedOn.json';
import isPropValid from '@emotion/is-prop-valid';
import sessionActions from '../store/session/actions';
import { useAccount, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { match } from 'ts-pattern';

// This implements the default behavior from styled-components v5
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function shouldForwardProp(propName: string, target: any): boolean {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

declare let window: ExtendedWindow;
const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const { newVersionAvailable: updateAvailable = false, flow } = useAppState<UserSettingsState>(
        s => s.userSettings
    );

    const { hostname } = window.location;

    const domainFlow = config.Auth.Flows ? config.Auth.Flows[hostname] : undefined;
    const defaultFlow = domainFlow || config.Auth.DefaultFlow;
    const getFlowFromQueryString = () => new URLSearchParams(location.search).get('flow');
    const getAuthority = (whichFlow?: string) =>
        config.Auth.Authority + (whichFlow || getFlowFromQueryString() || defaultFlow);
    const { user: { isInternal } = { isInternal: false }, failedFetchingUser } =
        useAppState<SessionState>(s => s.session);

    const enableCatch22Patch = useFlags().mx43A90Ff7Bb6F4A1F941D37Eb5F8Da32E;
    console.log('enableCatch22Patch', enableCatch22Patch);

    const authority = getAuthority(flow);
    const request = {
        scopes: ['openid', 'profile'],
        extraScopesToConsent: config.Auth.ApiScopes,
        state: location.pathname,
        authority,
    };
    const { login, acquireToken, error } = useMsalAuthentication(InteractionType.Silent, request);
    const { accounts, instance } = useMsal();

    const account = useAccount(accounts[0] || {});
    const accountsExist = accounts.length > 0;

    useEffect(() => {
        if (window.location.pathname.startsWith('/invitation/')) {
            return;
        }

        if (error && error.message.indexOf('AADB2C90118') > -1) {
            login(InteractionType.Redirect, {
                ...request,
                authority: getAuthority(config.Auth.PasswordResetFlow),
            });

            return;
        }

        if (error && error.message.indexOf('AADB2C90091') > -1) {
            login(InteractionType.Redirect, request);

            return;
        }

        if (error instanceof InteractionRequiredAuthError) {
            if (!localStorage.getItem('PATH_BEFORE_AUTH')) {
                localStorage.setItem('PATH_BEFORE_AUTH', location.pathname + location.search);
            }
            login(InteractionType.Redirect, request);
        }
    }, [error]);

    useEffect(() => {
        if (account) {
            instance.initialize().then(() => {
                instance.handleRedirectPromise().then(() =>
                    acquireToken(InteractionType.Silent, {
                        scopes: config.Auth.ApiScopes,
                        account,
                        authority,
                    }).then(response => {
                        if (response) {
                            dispatch(
                                sessionActions.saveUserSession(
                                    response.accessToken,
                                    response.expiresOn?.getTime() || 0,
                                    Date.now()
                                )
                            );
                            dispatch(setUserFlow(getFlowFromQueryString() ?? ''));
                        }
                    })
                );
            });
        }
    }, [account]);

    const { user, expiryTime, nextRefresh, showProcessingNotCompletedMessage } =
        useAppState<SessionState>(s => s.session);

    const renderRedirect = () => {
        const redirect = localStorage.getItem('PATH_BEFORE_AUTH');
        if (redirect) localStorage.removeItem('PATH_BEFORE_AUTH');

        const redirectPath = redirect || '/orders';
        const path = user && Object.keys(user).length > 0 ? redirectPath : '/usernotfound';

        return <Route path="*" element={<Navigate to={path} replace />} />;
    };

    const intervalTime = config.Environment !== 'PROD' ? 60000 : 300000;

    useEffect(() => {
        let setNewVersionAvailableTimeout: ReturnType<typeof setTimeout>;

        const showUpdateAvailable = () => {
            setNewVersionAvailableTimeout = setTimeout(() => {
                dispatch(setNewVersionAvailable(true));
            }, intervalTime);
        };

        const runCheckForNewVersion = setInterval(
            () => checkForNewVersion(showUpdateAvailable),
            intervalTime
        );

        return () => {
            if (setNewVersionAvailableTimeout) {
                clearTimeout(setNewVersionAvailableTimeout);
            }

            clearInterval(runCheckForNewVersion);
        };
    });

    if (accountsExist && user && user.organization && Date.now() < expiryTime) {
        if (nextRefresh && Date.now() > nextRefresh) {
            dispatch(sessionActions.refreshUser(Date.now()));
        }
    }

    const refresh = () => {
        dispatch(showProcessingNotComplete(false));
        window.location.reload();
    };

    const theme = walleyLightTheme;
    const source = createMemorySource('*');
    const history = createHistory(source ?? window);
    const userWithoutPermission = user?.permissions?.length === 0;

    return (
        <>
            {enableCatch22Patch && (
                <ThemeProvider theme={theme}>
                    {match({ failedFetchingUser, userWithoutPermission })
                        .with({ failedFetchingUser: true }, () => <UserNotFound />)
                        .with({ userWithoutPermission: true }, () => <UserWithoutPermission />)
                        .otherwise(() => (
                            <LocationProvider history={history}>
                                <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                                    {accountsExist && <>{updateAvailable && <RefreshMessage />}</>}
                                    <Routes>
                                        {accountsExist && (
                                            <>
                                                {routes.map((r, key) => {
                                                    return (
                                                        <Route
                                                            key={key}
                                                            path={r.path}
                                                            element={
                                                                <div>
                                                                    <NavBar user={user} />

                                                                    <Container>
                                                                        {
                                                                            r.component as React.ReactNode
                                                                        }
                                                                    </Container>
                                                                </div>
                                                            }
                                                        />
                                                    );
                                                })}
                                                {renderRedirect()}
                                            </>
                                        )}

                                        <Route
                                            path="/invitation/:invitationId"
                                            element={<InviteLandingPage />}
                                        />
                                        <Route path="*" element={<LoadingScreen />} />
                                    </Routes>

                                    {user &&
                                        user.permissions &&
                                        user?.permissions.length > 0 &&
                                        accountsExist && (
                                            <>
                                                <LockedModal
                                                    small
                                                    isShown={showProcessingNotCompletedMessage}
                                                    hide={() =>
                                                        dispatch(showProcessingNotComplete(false))
                                                    }
                                                >
                                                    <ProcessContainer>
                                                        Order has been updated and will show the
                                                        correct data on refresh.
                                                        <StyledButton
                                                            large
                                                            onClick={() => refresh()}
                                                        >
                                                            Refresh
                                                        </StyledButton>
                                                    </ProcessContainer>
                                                </LockedModal>

                                                {!isInternal && (
                                                    <Survey
                                                        surveyActivatedOn={
                                                            surveyActivatedOn.surveyActivatedOn
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    <WalleyGlobalStyles theme={walleyLightTheme} />
                                </StyleSheetManager>
                            </LocationProvider>
                        ))}
                </ThemeProvider>
            )}
            {!enableCatch22Patch && (
                <LocationProvider history={history}>
                    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                        <ThemeProvider theme={theme}>
                            {user &&
                                user.permissions &&
                                user?.permissions.length > 0 &&
                                accountsExist && <>{updateAvailable && <RefreshMessage />}</>}
                            <Routes>
                                {failedFetchingUser && (
                                    <Route
                                        path="*"
                                        element={<Navigate to="/usernotfound" replace />}
                                    />
                                )}

                                {user?.permissions?.length === 0 && (
                                    <Route
                                        path="*"
                                        element={<Navigate to="/userwithoutpermission" replace />}
                                    />
                                )}

                                {user &&
                                    user.permissions &&
                                    user?.permissions.length > 0 &&
                                    accountsExist && (
                                        <>
                                            {routes.map((r, key) => {
                                                return (
                                                    <Route
                                                        key={key}
                                                        path={r.path}
                                                        element={
                                                            <div>
                                                                <NavBar user={user} />

                                                                <Container>
                                                                    {r.component as React.ReactNode}
                                                                </Container>
                                                            </div>
                                                        }
                                                    />
                                                );
                                            })}
                                            {renderRedirect()}
                                        </>
                                    )}

                                <Route
                                    path="/invitation/:invitationId"
                                    element={<InviteLandingPage />}
                                />
                                <Route
                                    key="usernotfound"
                                    path="/usernotfound"
                                    element={<UserNotFound />}
                                />
                                <Route
                                    key="userwithoutpermission"
                                    path="/userwithoutpermission"
                                    element={<UserWithoutPermission />}
                                />
                                <Route path="*" element={<LoadingScreen />} />
                            </Routes>

                            {user &&
                                user.permissions &&
                                user?.permissions.length > 0 &&
                                accountsExist && (
                                    <>
                                        <LockedModal
                                            small
                                            isShown={showProcessingNotCompletedMessage}
                                            hide={() => dispatch(showProcessingNotComplete(false))}
                                        >
                                            <ProcessContainer>
                                                Order has been updated and will show the correct
                                                data on refresh.
                                                <StyledButton large onClick={() => refresh()}>
                                                    Refresh
                                                </StyledButton>
                                            </ProcessContainer>
                                        </LockedModal>

                                        {!isInternal && (
                                            <Survey
                                                surveyActivatedOn={
                                                    surveyActivatedOn.surveyActivatedOn
                                                }
                                            />
                                        )}
                                    </>
                                )}

                            <WalleyGlobalStyles theme={walleyLightTheme} />
                        </ThemeProvider>
                    </StyleSheetManager>
                </LocationProvider>
            )}
        </>
    );
};

const launchDarklyClientId = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID;

export default withLDProvider({
    clientSideID: config.LaunchDarklyApiKey || launchDarklyClientId || '',
    flags: {
        'mx-2f27f5db-3897-47de-af36-6e918362e136': true,
        'mx-43a90ff7-bb6f-4a1f-941d-37eb5f8da32e': false,
    },
    context: {
        key: 'anonymous-users-shared-key',
    },
})(App);

const ProcessContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 3rem;
    gap: 3rem;
`;

const StyledButton = styled(Button)`
    width: 10rem;
    padding: 1rem;
    align-self: flex-end;
`;

const Container = styled.div`
    margin: 1rem auto 0 auto;
`;
