/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { ReactNode, useEffect } from 'react';
import styled, { DefaultTheme, keyframes, withTheme } from 'styled-components';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import Keys from '../../../types/Keys';
import IconButton from '../Button/IconButton';

interface Props {
    isVisible: boolean;
    toggle?: () => void;
    children?: ReactNode;
    theme: DefaultTheme;
}

const SidebarMenu: React.FC<Props> = ({ isVisible, toggle, children, theme }: Props) => {
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === Keys.ESCAPE && isVisible && toggle) {
                toggle();
            }
        };

        isVisible
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');

        document.addEventListener('keydown', onKeyDown, false);
        if (isVisible) document.documentElement.style.overflowY = 'hidden';

        return () => {
            if (isVisible) document.documentElement.style.overflowY = 'initial';

            document.removeEventListener('keydown', onKeyDown, false);
            document.body.style.overflow = 'unset';
        };
    }, [isVisible, toggle]);

    return (
        <>
            <Backdrop isVisible={isVisible} onClick={() => toggle && toggle()} />
            <Menu
                isVisible={isVisible}
                onAnimationEnd={() => {
                    if (!isVisible && toggle) toggle();
                }}
            >
                <SideBarMenuHeader>
                    <IconButton transparent onClick={() => toggle && toggle()}>
                        <CloseLineIcon size={theme.icon.size.small} />
                    </IconButton>
                </SideBarMenuHeader>

                {children}
            </Menu>
        </>
    );
};



interface VisibleProps {
    isVisible: boolean;
}

const Animation = keyframes`
    from {
        transition: 1s;
        width: 0;

    }
    to {
        width: 100;
        transition: 1.5s;
    }
`;

const Menu = styled.div<VisibleProps>`
    height: 100vh;
    width: ${props => (props.isVisible ? '50rem' : 0)};
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    animation: ${Animation} 0.2s ease-in-out;
    z-index: 5;
    box-shadow: 0 0 1rem 0.1rem #b1b1b16b;
`;

const Backdrop = styled.div<VisibleProps>`
    display: ${props => (props.isVisible ? 'block' : 'none')};
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    position: fixed;
    background-color: #fefefe7a;
    z-index: 4;
`;

const SideBarMenuHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1rem;
`;

export default withTheme(SidebarMenu) as React.ComponentType<Omit<Props, 'theme'>>;
