import React, { ReactNode } from 'react';
import styled from 'styled-components';
import StatusTypes from '../../../types/StatusTypes';
import Status from '../Status/Status';

export interface Props {
    id?: string;
    title?: string;
    information?: ReactNode;
    children?: ReactNode;
    status?: string;
    statusText?: string;
    hideBorder?: boolean;
    color?: string;
}

const CardHeader: React.FC<Props> = ({
    id,
    title,
    information,
    children,
    status,
    statusText,
    hideBorder,
    color,
}: Props) => {
    return (
        <Container hideBorder={hideBorder} id={id}>
            <TitleContainer>
                {title && <Title>{title}</Title>}
                {status && (
                    <Status
                        status={status}
                        background="transparent"
                        type={StatusTypes.Order}
                        text={statusText}
                    />
                )}
                {information && <Information>{information}</Information>}
            </TitleContainer>
            {children && children}
        </Container>
    );
};



interface ContainerProps {
    hideBorder?: boolean;
    color?: string;
}

const Container = styled.div<ContainerProps>`
    display: grid;
    min-height: 5rem;
    grid-template-columns: auto auto;
    padding: ${props => props.theme.layout.padding.medium};
    border-bottom: ${props =>
        props.hideBorder ? '0' : `0.1rem solid ${props.theme.colors.card.border}`};
`;

const TitleContainer = styled.div`
    display: grid;
    grid-template-columns: 13rem auto;
    padding-left: 0.5rem;
`;

const Title = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    align-self: center;
    margin-right: ${props => props.theme.layout.padding.xxl};
`;

const Information = styled.div`
    align-self: center;
`;

export default CardHeader;
