import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

import { useManagementTags } from '../../../services/hooks/useTags';
import usePermissions from '../../../services/hooks/usePermissions';
import { WithMsalProps, withMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../../store';
import sessionActions from '../../../store/session/actions';

type DDLinksSectionType = {
    ddLinkSection: Array<DDLinkType>;
};

type DDLinkType = {
    name: string;
    path?: string;
    needPermission?: boolean;
    link?: string;
    show?: boolean;
};

interface NavbarDropDownContentProps extends WithMsalProps {
    toggle: () => void;
}

const NavbarDropDownContent: React.FC<NavbarDropDownContentProps> = ({
    toggle,
    msalContext,
}: NavbarDropDownContentProps) => {
    const { userManagementGroupTags, userManagementUserTags } = useManagementTags();
    const hasUserRead = usePermissions(userManagementUserTags).hasRead;
    const hasGroupRead = usePermissions(userManagementGroupTags).hasRead;
    const hasPermission = hasUserRead || hasGroupRead;
    const dispatch = useAppDispatch();

    const onLogout = () => {
        dispatch(sessionActions.userUnauthorized());
        msalContext.instance.logout();
    };

    const dropDownLinks: DDLinksSectionType[] = [
        {
            ddLinkSection: [
                {
                    name: 'FAQ',
                    link: 'https://dev.walleypay.com/docs/merchanthub/faq',
                    show: true,
                },
            ],
        },
        {
            ddLinkSection: [
                {
                    name: 'Manage Organization',
                    path: '/admin/manage-organization',
                    needPermission: true,
                    show: true,
                },
            ],
        },
    ];

    const lastSectionIndex = dropDownLinks.length - 1;

    return (
        <>
            {dropDownLinks.map((ddLinkSection: DDLinksSectionType, index: number) => {
                const section = ddLinkSection.ddLinkSection;
                const key = `key${index}`;

                return (
                    <React.Fragment key={key}>
                        {section.map((ddLink: DDLinkType, linkIndex: number) => {
                            const pathIndex = ddLink.path && linkIndex.toString + ddLink.path;

                            return (!ddLink.needPermission || hasPermission) &&
                                ddLink.show === true ? (
                                <React.Fragment key={ddLink.name}>
                                    {ddLink.path && !ddLink.link && (
                                        <DropDownItem
                                            key={pathIndex}
                                            to={ddLink.path}
                                            onClick={() => toggle()}
                                        >
                                            <DropDownItemSpan key={`dropdownItem${pathIndex}`}>
                                                {ddLink.name}
                                            </DropDownItemSpan>
                                        </DropDownItem>
                                    )}

                                    {ddLink.link && (
                                        <DDLink
                                            key={`${key}-link`}
                                            target="blank"
                                            href={ddLink.link}
                                        >
                                            {ddLink.name}
                                        </DDLink>
                                    )}
                                    {(index !== lastSectionIndex || hasPermission) && (
                                        <DropDownSectionBorder />
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment key={`${key}-pathLink`} />
                            );
                        })}
                    </React.Fragment>
                );
            })}

            {dropDownLinks.length < 2 && <DropDownSectionBorder />}
            <>
                <LogoutButton onClick={onLogout}>
                    <DropDownItemSpan>Log out</DropDownItemSpan>
                </LogoutButton>
            </>
        </>
    );
};



const DropDownItemSpan = styled.span`
    font-size: ${props => props.theme.text.size.medium};
    &:hover {
        cursor: pointer;
    }
`;

const DDLink = styled.a`
    font-size: ${props => props.theme.text.size.medium};
    padding: 1rem 0 1rem 1.5rem;
    display: block;
    text-align: left;
    text-decoration: none;
    font-family: ${props => props.theme.text.type.medium};
    color: ${props => props.theme.colors.navBar.background};
    width: 16.8rem;
    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.colors.text.subtle};
    }
`;

const LogoutButton = styled.button`
    padding: 1rem 0 1rem 1.5rem;
    background: transparent;
    border: 0;
    width: 100%;
    display: block;
    text-align: left;
    text-decoration: none;
    font-family: ${props => props.theme.text.type.medium};
    color: ${props => props.theme.colors.navBar.background};
    &:focus {
        outline: 0;
    }

    &:hover {
        background-color: ${props => props.theme.colors.text.subtle};
        cursor: pointer;
    }
`;

const DropDownItem = styled(NavLink)`
    padding: 1rem 0 1rem 1.5rem;
    display: block;
    text-align: left;
    text-decoration: none;
    font-family: ${props => props.theme.text.type.medium};
    color: ${props => props.theme.colors.navBar.background};

    &:hover {
        background-color: ${props => props.theme.colors.text.subtle};
    }
`;

const DropDownSectionBorder = styled.div`
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    align-self: left;
`;

export default withMsal(NavbarDropDownContent);
