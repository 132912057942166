import styled from 'styled-components';

interface Props {
    padding?: boolean;
    sticky?: boolean;
}

const StickyNote = styled.div<Props>`
    ${props => props.padding && `padding: ${props.theme.layout.padding.large};`}
    background-color: ${props => props.theme.colors.accent};
    margin-bottom: 1rem;
    & *:first-child {
        margin-top: 0;
    }
    ${props => props.sticky && `position: sticky; top: 7rem;`}
`;

export default StickyNote;


