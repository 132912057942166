import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import SmartphoneLineIcon from 'remixicon-react/SmartphoneLineIcon';
import PhoneLineIcon from 'remixicon-react/PhoneLineIcon';
import PhoneNumberType from '../../../types/PhoneNumber';

interface Props {
    children?: string;
    type?: PhoneNumberType;
    title?: string;
    theme: DefaultTheme;
    hidden?: boolean;
}

const Phone: React.FC<Props> = ({ children, type, theme, title, hidden = false }: Props) => {
    if (hidden) return <></>;

    return (
        <Container title={title ?? ''}>
            {type === PhoneNumberType.Home ? (
                <PhoneLineIcon size={theme.icon.size.small} />
            ) : (
                <SmartphoneLineIcon size={theme.icon.size.small} />
            )}

            {children ? (
                <StyledPhone>{children}</StyledPhone>
            ) : (
                <NotAvailable>Not available</NotAvailable>
            )}
        </Container>
    );
};

export default withTheme(Phone) as React.ComponentType<Omit<Props, 'theme'>>;



const Container = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    grid-gap: 0.8rem;
    width: 100%;
`;

const StyledPhone = styled.span`
    font-size: ${props => props.theme.text.size.medium};
    color: ${props => props.theme.colors.text.primary};
`;

const NotAvailable = styled(StyledPhone)`
    color: ${props => props.theme.colors.text.subtleSecondary};
`;
