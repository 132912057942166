import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../../services/helpers/dateTimeFormats';

interface Props {
    children: Date;
    flat?: boolean;
    onlyDate?: boolean;
}

interface StyleProps {
    flat: boolean;
}

const StyledDate = styled.span<StyleProps>`
    margin-right: 0.2rem;
    font-family: ${props =>
        props.flat ? props.theme.text.font.regular : props.theme.text.font.medium};
`;

const StyledTime = styled.span`
    font-size: 1.5rem;
    font-family: ${props => props.theme.text.font.medium};
    color: ${props => props.theme.colors.text.secondary};
    margin-left: ${props => props.theme.layout.padding.xsmall};
`;

const DateTime: React.FC<Props> = ({ children, flat, onlyDate }: Props) => {
    const timeArray = formatDate(new Date(children));
    return (
        <>
            {onlyDate ? (
                <StyledDate flat>{timeArray.yyyyMMdd}</StyledDate>
            ) : (
                <>
                    <StyledDate flat>{timeArray.yyyyMMdd}</StyledDate>
                    <StyledTime>{timeArray.HHmm}</StyledTime>
                </>
            )}
        </>
    );
};



export default DateTime;
