import React, { useCallback, useEffect, useState, KeyboardEvent } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import useModal from '../../../services/hooks/useModal';
import { SearchTerm } from '../../../types/response/SearchTerm';
import SearchFilterButton from '../Button/SearchFilterButton';
import CheckBox from '../Checkbox/Checkbox';
import FilterDropdown from '../Dropdown/FilterDropdown';
import { orderStatus, paylinkStatus } from '../../../services/helpers/status';
import DecoratorInput from '../Input/DecoratorInput';
import { ConnectedStore } from '../../../types/ConnectedStore';
import StoreLabel, { LabelTypes } from '../Store/StoreLabel';
import TermFragmentContainer from './TermFragmentContainer';
import TermCategory from '../../../types/filter/TermCategory';

interface TermProps {
    category: TermCategory;
    term: SearchTerm;
    currentFilters: string[];
    onTermFilterChange: (termKey: string, termFilter: string[]) => void;
    theme: DefaultTheme;
    showSearchbar?: string[];
    availableStores?: ConnectedStore[];
    limitShow?: number;
}

type TermItem = {
    key: string;
    value: string;
    count: number;
    metaData: ConnectedStore;
};

const Term: React.FC<TermProps> = ({
    category,
    term,
    onTermFilterChange,
    currentFilters,
    theme,
    showSearchbar,
    availableStores,
    limitShow,
}: TermProps) => {
    const sortedValues = React.useMemo(
        () =>
            term.values
                .map(
                    x =>
                        ({
                            key: term.key,
                            value: x.value,
                            count: x.count,
                            metaData:
                                (availableStores && availableStores.find(y => y.id === x.value)) ??
                                {},
                        } as TermItem)
                )
                .sort(
                    (a, b) =>
                        (availableStores?.indexOf(a.metaData) ?? -1) -
                        (availableStores?.indexOf(b.metaData) ?? -1)
                ),
        [term.key, term.values, availableStores]
    );

    const [state, setState] = useState<Set<string>>(new Set<string>());
    const [search, setSearch] = useState<string>('');
    const [show, setShow] = useState<TermItem[]>(sortedValues);

    const [pushSearch, setPushSearch] = useState(false);

    const { isShown, toggle } = useModal();
    const filterExists = state.size !== 0;

    const onTermFilterChangeCallback = useCallback(onTermFilterChange, []);

    const handleFilterChange = () => {
        const arr = Array.from(state);
        if (arr.length > 0) {
            onTermFilterChange(term.key, arr);
        } else {
            onTermFilterChange(term.key, []);
        }
    };

    const onChange = (e: HTMLInputElement) => {
        if (e.checked) {
            state.add(e.value);
            setState(new Set<string>(state));
            handleFilterChange();
            return;
        }

        state.delete(e.value);
        setState(new Set<string>(state));
        handleFilterChange();
    };

    const onClear = () => {
        setState(new Set<string>());
        onTermFilterChange(term.key, []);
        if (isShown) {
            toggle();
        }
    };

    const getTermTitle = (termKeyword: string) => {
        switch (termKeyword) {
            case 'status':
                return 'Status';
            case 'storeId':
                return 'Stores';
            case 'reference':
                return 'Reference';
            case 'currency':
                return 'Currency';
            default:
                return '';
        }
    };

    const getStatusText = (value: string) => {
        switch (category) {
            case TermCategory.Order:
                return orderStatus(value).text;
            case TermCategory.PayLink:
                return paylinkStatus(value).text;
            default:
                return '';
        }
    };

    const getTermValueTitle = (termValue: string) => {
        switch (term.key) {
            case 'status':
                return getStatusText(termValue);
            case 'currency':
                return termValue.toUpperCase();
            default:
                return termValue;
        }
    };

    const getTermStatus = (amount: number) => {
        const iterate = state.values();

        if (amount === 0) {
            return 'All';
        }
        if (amount === 1) {
            return getTermValueTitle(iterate.next().value ?? '');
        }
        return amount;
    };

    const addTermByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            const alreadyCheckedFilterd = show.filter(x => !state.has(x.value));

            if (alreadyCheckedFilterd.length > 1) return;

            setState(new Set<string>([...state, ...show.map(x => x.value)]));
            toggle();
            setSearch('');
            setPushSearch(true);
        }
    };

    useEffect(() => {
        if (pushSearch) {
            setPushSearch(false);
            handleFilterChange();
        }
    }, [pushSearch]);

    useEffect(() => {
        const decoratorInput = document.getElementById(`${term.key}-searchFilter`);

        if (decoratorInput) {
            decoratorInput.focus();
        }
    });

    useEffect(() => {
        const selectedFilters = currentFilters
            .map(filter => {
                return term.values.find(
                    filterValue => filterValue.value.toLowerCase() === filter.toLowerCase()
                );
            })
            .filter(f => f !== undefined)
            .map(f => f?.value ?? '');

        setState(new Set<string>(selectedFilters));

        if (selectedFilters.length !== currentFilters.length)
            onTermFilterChangeCallback(term.key, selectedFilters);
    }, [currentFilters, term.key, term.values]);

    useEffect(() => {
        if (!search) setShow(sortedValues);
        if (showSearchbar && !showSearchbar.includes(term.key)) return;
        if (!search && currentFilters.length === 0) return;

        const filteredItems = sortedValues.filter(
            x =>
                (x.value.includes(search) ||
                    x.metaData.selfReference
                        ?.toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase()) ||
                    (x.metaData.storeName &&
                        x.metaData.storeName.toLowerCase().includes(search.toLowerCase()))) &&
                !state.has(x.value)
        );

        const checked = sortedValues.filter(x => state.has(x.value));

        setShow([...checked, ...filteredItems]);
    }, [search]);

    const list = limitShow ? show.slice(0, limitShow) : show;

    return (
        <Terms>
            <FilterDropdown
                toggle={toggle}
                isShown={isShown}
                initiator={
                    <SearchFilterButton
                        modalToggled={isShown}
                        onClick={toggle}
                        onClear={onClear}
                        filterExists={filterExists}
                        criteria={getTermTitle(term.key)}
                    >
                        {getTermStatus(state.size)}
                    </SearchFilterButton>
                }
            >
                <TermContainer>
                    {showSearchbar && showSearchbar.includes(term.key) && (
                        <TermsHeader>
                            <DecoratorInput
                                dataTestId="term-searchbar"
                                type="text"
                                name="searchFilter"
                                onChange={e => setSearch(e.target.value)}
                                value={search}
                                placeholder="Search"
                                id={`${term.key}-searchFilter`}
                                decorator={<SearchLineIcon size={theme.icon.size.small} />}
                                onKeyDown={addTermByEnter}
                            />
                        </TermsHeader>
                    )}
                    <TermContentContainer>
                        {React.useMemo(
                            () =>
                                list.map(x => {
                                    return (
                                        <React.Fragment key={`${x.key}${x.value}`}>
                                            <CheckBox
                                                dataTestId="term-checkbox"
                                                id={`${x.key}${x.value}`}
                                                value={x.value}
                                                name={x.key}
                                                checked={state.has(x.value)}
                                                onChange={e => onChange(e.target)}
                                            >
                                                {x.key === 'storeId' ? (
                                                    <StoreLabel
                                                        storeId={x.value}
                                                        storeName={x.metaData?.storeName}
                                                        countryCode={x.metaData?.countryCode}
                                                        selfReference={x.metaData?.selfReference}
                                                        customerSalesType={
                                                            x.metaData?.customerSalesType
                                                        }
                                                        labelType={LabelTypes.LABEL}
                                                    />
                                                ) : (
                                                    <TermFragmentContainer
                                                        value={getTermValueTitle(x.value)}
                                                        count={x.count}
                                                    />
                                                )}
                                            </CheckBox>
                                        </React.Fragment>
                                    );
                                }),
                            [show, state]
                        )}
                    </TermContentContainer>
                </TermContainer>
            </FilterDropdown>
        </Terms>
    );
};



const Terms = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
`;
const TermsHeader = styled.div`
    padding: 0 2rem 0.5rem 2rem;
`;

const TermContainer = styled.div`
    padding: 2rem 0 0 0;
`;

const TermContentContainer = styled.div`
    padding: 0 1rem 2rem 2rem;
    width: 25rem;
    height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
`;

export default withTheme(Term) as React.ComponentType<Omit<TermProps, 'theme'>>;
