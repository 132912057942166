import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface RadioProps {
    id: string;
    name: string;
    children: ReactNode;
    setRef?: (instance: HTMLInputElement | null) => void;
    dataTestId?: string;
}
type RadioInputProps = RadioProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Radio = ({
    id,
    name,
    children,
    checked,
    onChange,
    onClick,
    value,
    setRef,
    defaultChecked,
    dataTestId,
}: RadioInputProps) => {
    return (
        <Container>
            <RadioWrapper>
                <Input
                    id={id}
                    name={name}
                    type="radio"
                    checked={checked}
                    onChange={onChange}
                    value={value}
                    defaultChecked={defaultChecked}
                    ref={ref => setRef && setRef(ref)}
                    data-testid={dataTestId}
                />
                <Label htmlFor={id} />
            </RadioWrapper>
            <TextWrapper onClick={onClick}>{children}</TextWrapper>
        </Container>
    );
};



const TextWrapper = styled.div`
    align-self: center;
    cursor: pointer;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3rem;
`;

const RadioWrapper = styled.div`
    position: relative;
`;

const Label = styled.label`
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    left: 0;
    position: absolute;
    top: 0;
    height: 2rem;
    width: 2.02rem;
    top: -0.3rem;

    &:after {
        border: 0.3rem solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 0.55rem;
        opacity: 0;
        position: absolute;
        top: 0.4rem;
        left: 0.4rem;
        transform: rotate(-45deg);
        width: 0.9rem;
    }
`;

const Input = styled.input`
    visibility: hidden;
    display: none;
    &:checked + ${Label} {
        background-color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
        transition: 0.3s;

        &::after {
            opacity: 1;
        }
    }
`;

export default Radio;
