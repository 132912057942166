import React from 'react';
import styled from 'styled-components';
import MessageContainer from '../MessageContainer';

interface InformationProps {
    messageHeader?: string;
    message?: string | string[];
    messages?: (string | JSX.Element)[];
}

const InformationMessage: React.FC<InformationProps> = ({
    message,
    messages,
    messageHeader,
}: InformationProps) => {
    return (
        <MessageContainer type="Info" header={messageHeader}>
            <Message>
                {messages ? (
                    messages.map((m, i) => {
                        const key = `_${i}`;
                        return <p key={key}>{m}</p>;
                    })
                ) : (
                    <p>{message}</p>
                )}
            </Message>
        </MessageContainer>
    );
};



const Message = styled.div`
    width: 100%;
`;

export default InformationMessage;
