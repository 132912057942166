import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import MailLineIcon from 'remixicon-react/MailLineIcon';
import isNullOrEmpty from '../../../services/helpers/isNullOrEmpty';

interface Props {
    children?: string;
    hidden?: boolean;
    title?: string;
    theme: DefaultTheme;
}

const Email: React.FC<Props> = ({ children, title, theme, hidden = false }: Props) => {
    if (hidden) return <></>;

    return (
        <Container title={title ?? ''}>
            <MailLineIcon size={theme.icon.size.small} />
            {!isNullOrEmpty(children) ? (
                <StyledEmailHref href={`mailto:${children}`}>{children}</StyledEmailHref>
            ) : (
                <NotAvailable>Not available</NotAvailable>
            )}
        </Container>
    );
};

export default withTheme(Email) as React.ComponentType<Omit<Props, 'theme'>>;



const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow-wrap: anywhere;
    grid-gap: 0.8rem;
`;

const StyledEmail = styled.a`
    color: ${props => props.theme.colors.text.primary};
    text-decoration: none;
    font-size: ${props => props.theme.text.size.medium};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 23rem;
`;

const StyledEmailHref = styled(StyledEmail)`
    &:hover {
        text-decoration: underline;
    }
`;

const EmptyEmail = styled(StyledEmail)`
    color: ${props => props.theme.colors.text.primary};
    text-decoration: none;
    font-size: ${props => props.theme.text.size.medium};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 23rem;
`;

const NotAvailable = styled(EmptyEmail)`
    color: ${props => props.theme.colors.text.subtleSecondary};
`;
