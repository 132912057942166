import styled from 'styled-components';
import React, { ReactNode } from 'react';

interface CheckBoxProps {
    children?: ReactNode;
    backgroundColor?: string;
    checkMarkColor?: string;
    dataTestId?: string;
    setRef?: (instance: HTMLInputElement | null) => void;
    hide?: boolean;
    noMargin?: boolean;
}

interface ColorProps {
    backgroundColor: string | undefined;
    checkMarkColor: string | undefined;
    labelExist: boolean;
    disabled?: boolean;
    noMargin: boolean;
}

type CheckboxInputType = CheckBoxProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const CheckBox: React.FC<CheckboxInputType> = ({
    onChange,
    id,
    value,
    children,
    name,
    backgroundColor,
    checkMarkColor,
    checked,
    dataTestId,
    setRef,
    disabled,
    hide,
    defaultChecked,
    noMargin = false,
    onBlur,
    onClick,
}: CheckboxInputType) => {
    const labelExist = !!children;

    return !hide ? (
        <Container
            labelExist={labelExist}
            checkMarkColor={checkMarkColor}
            backgroundColor={backgroundColor}
            htmlFor={id}
            disabled={disabled}
            noMargin={noMargin}
        >
            {children && <Label>{children}</Label>}
            <CheckBoxInput
                name={name}
                value={value}
                id={id}
                type="checkbox"
                checked={checked}
                ref={setRef}
                onChange={onChange}
                onBlur={onBlur}
                onClick={onClick}
                data-testid={dataTestId}
                disabled={disabled}
                defaultChecked={defaultChecked}
            />
            <Checkmark disabled={disabled} className="checkmark" />
        </Container>
    ) : (
        <></>
    );
};



const CheckBoxInput = styled.input`
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
`;

const Label = styled.span`
    line-height: ${props => props.theme.layout.padding.large};
    cursor: pointer;
`;

const Checkmark = styled.span<{ disabled?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.2rem solid #b6b6b6;
    border-radius: 0.3rem;
    &:after {
        content: '';
        position: absolute;
        display: none;
    }
    ${props =>
        props.disabled &&
        `background-color: #ececec;
        opacity: 0.35;`}
`;

const Container = styled.label<ColorProps>`
    ${props => (props.labelExist ? ' display: block' : '')};
    position: relative;
    padding-left: ${props => (props.labelExist ? '3rem' : '2rem')};
    margin: ${props => (props.noMargin ? 0 : `${props.theme.layout.margin.xmedium} 0`)};
    font-size: ${props => props.theme.text.size.small};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input:checked ~ .checkmark {
        background-color: ${props =>
            props.backgroundColor ? props.backgroundColor : props.theme.colors.primary};
        border: 0.2rem solid
            ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colors.primary)};
    }
    .checkmark:after {
        left: 0.3rem;
        top: 0.2rem;
        width: 0.6rem;
        height: 0.6rem;
        border: solid
            ${props => (props.checkMarkColor ? props.checkMarkColor : props.theme.colors.light)};
        border-width: 0 0.35rem 0.35rem 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    input:checked ~ .checkmark:after {
        display: block;
    }

    &:hover ${CheckBoxInput} ~ .checkmark {
        ${props =>
            !props.disabled &&
            `
        transition: all 0.2s;
        border: 0.2rem solid #e6e6e6;`}
    }
`;

export default CheckBox;
