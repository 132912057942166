import React from 'react';
import styled from 'styled-components';
import { formatNumber } from '../../../services/helpers/numberFormats';

interface Props {
    label: string;
    amount: number;
    vat?: number;
}

const SubTotal: React.FC<Props> = ({ amount, vat, label }: Props) => {
    const formattedAmount = formatNumber(amount);

    return (
        <Container>
            <Label>{label}</Label>
            <Amount>
                {formattedAmount} {vat !== undefined ? `(${formatNumber(vat || 0)})` : ''}
            </Amount>
        </Container>
    );
};



const Container = styled.div`
    display: grid;
    grid-template-areas: 'label . amount';
    grid-template-columns: 30rem 5rem auto;
    font-size: ${props => props.theme.text.size.small};
    text-align: right;
    span {
        font-family: ${props => props.theme.text.font.medium};
    }
`;

const Label = styled.label`
    grid-area: label;
`;

const Amount = styled.span`
    grid-area: amount;
    min-width: 5rem;
`;

export default SubTotal;
