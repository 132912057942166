/* eslint-disable react/prop-types */
import styled, { css } from 'styled-components';
import { darken } from 'polished';
import React, { ReactNode } from 'react';

interface ButtonProps {
    color?: string;
    large?: boolean;
    distinct?: boolean;
    transparent?: boolean;
    children: ReactNode;
    dataTestId?: string;
}

type Props = ButtonProps & React.HTMLProps<HTMLButtonElement>;

const IconButton: React.FC<Props> = ({
    children,
    hidden,
    color,
    large,
    transparent,
    distinct,
    onClick,
    dataTestId,
    disabled,
    title,
}: Props) => {
    return (
        <IconButtonContainer
            onClick={onClick}
            color={color}
            hidden={hidden}
            large={large}
            transparent={transparent}
            distinct={distinct}
            data-testid={dataTestId}
            disabled={disabled}
            title={title}
        >
            <IconWrapper>{children}</IconWrapper>
        </IconButtonContainer>
    );
};



const IconWrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
`;

const IconButtonContainer = styled.button<ButtonProps>`
    cursor: ${props => (props.disabled ? 'no-drop;' : 'pointer')};
    border-radius: 50%;
    border: 0.2rem solid transparent;
    color: ${props => props.theme.colors.button.primary.text};
    font-size: 1.5rem;
    text-align: center;
    transition: 0.3s;
    padding: 0.5rem;
    height: 3rem;
    width: 3rem;
    outline: none;
    margin: 0.2rem;
    background: ${props =>
        (props.disabled && props.theme.colors.disabled) ||
        (props.transparent && 'transparent !important')};

    &:hover {
        background: ${props =>
            props.disabled
                ? props.theme.colors.disabled
                : darken(0.03, props.color === 'primary' ? props.theme.colors.accent : '#c6afda')};
    }
    ${props =>
        props.color === 'primary'
            ? css`
                  color: ${props.theme.colors.light};
                  background: ${props.disabled
                      ? props.theme.colors.disabled
                      : props.theme.colors.primary};
                  &:focus {
                      border: 0.2rem solid ${props.theme.colors.light};
                      box-shadow: 0 0 0 0.125rem ${props.theme.colors.primary};
                  }
              `
            : css`
                  color: ${props.theme.colors.primary};
                  background: ${props.disabled
                      ? props.theme.colors.disabled
                      : props.theme.colors.accent};
                  &:focus {
                      border: 0.2rem solid ${props.theme.colors.primary};
                  }
              `}
`;

export default IconButton;
