import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ResponseError from '../../types/response/ResponseError';
import ErrorMessage from '../atoms/Message/Error/ErrorMessage';
import FooterButtonContainer from '../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../atoms/Modal/HeaderTitle';
import ModalContainer from '../atoms/Modal/ModalContainer';
import Spinner from '../atoms/Spinner/Spinner';
import { LinkData } from '../../types/response/LinkData';
import orderActions from '../../store/order/actions';
import StatusTypes from '../../types/StatusTypes';
import accountActions from '../../store/account/actions';
import { ErrorType } from '../../types/response/ErrorCodes';
import SuccessMessage from '../atoms/Message/Success/SuccessMessage';
import { clearExtendDueDate } from '../../store/order/orderSlice';
import Button from '../atoms/Button/Button';
import { useAppDispatch } from '../../store';

interface ExtendDateProps {
    identifier: string;
    extendLink?: LinkData;
    hide: () => void;
    theme: DefaultTheme;
    type: StatusTypes;
    isExtending: boolean;
    isExtended: boolean;
    extendFailed: boolean;
    error: ResponseError | undefined;
}

const ExtendDate: React.FC<ExtendDateProps> = ({
    identifier,
    extendLink,
    hide,
    theme,
    type,
    isExtending,
    isExtended,
    error,
    extendFailed,
}: ExtendDateProps) => {
    const dispatch = useAppDispatch();

    const onExtend = () => {
        dispatch(clearExtendDueDate());

        if (extendLink && type === StatusTypes.Order)
            dispatch(orderActions.extendDueDate(extendLink));

        if (extendLink && type === StatusTypes.Invoice)
            dispatch(accountActions.extendAccount(extendLink));
    };

    const getDateTypeText = () => {
        switch (type) {
            case StatusTypes.Order:
                return 'expiration';
            case StatusTypes.Invoice:
                return 'due';
            default:
                return '';
        }
    };

    const dateTypeText = getDateTypeText();

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Extend {dateTypeText} date</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                {!isExtended && (
                    <MessageContainer>
                        Extend the {dateTypeText} date of {type}
                        <StyledSpan>{identifier}</StyledSpan>?
                    </MessageContainer>
                )}

                {!isExtending && isExtended && (
                    <SuccessMessage>
                        {type} {dateTypeText} date extended!
                    </SuccessMessage>
                )}
            </ModalContainer>

            {extendFailed && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.Extend} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    {!isExtended && (
                        <>
                            <Button disabled={isExtending} onClick={hide} large>
                                {extendFailed ? 'Close' : 'No'}
                            </Button>
                            <Button
                                tabIndex={0}
                                onClick={onExtend}
                                primary
                                large
                                disabled={isExtending || isExtended}
                            >
                                {isExtending ? (
                                    <Spinner color={theme.colors.light} size={8} loading />
                                ) : (
                                    <span>{extendFailed ? 'Retry' : 'Yes'}</span>
                                )}
                            </Button>
                        </>
                    )}
                    {isExtended && (
                        <>
                            <Button
                                tabIndex={0}
                                onClick={hide}
                                primary
                                large
                                disabled={isExtending}
                            >
                                {isExtending ? (
                                    <Spinner color={theme.colors.light} size={8} loading />
                                ) : (
                                    <span>Close</span>
                                )}
                            </Button>
                        </>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};



const MessageContainer = styled.div`
    justify-content: center;
    max-width: 40rem;
    margin-right: ${props => props.theme.layout.padding.large};
`;

const StyledSpan = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin: 0.25rem;
    max-width: 100%;
`;

export default withTheme(ExtendDate) as React.ComponentType<Omit<ExtendDateProps, 'theme'>>;
