import styled, { DefaultTheme, keyframes, withTheme } from 'styled-components';
import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import GiftLineIcon from 'remixicon-react/GiftLineIcon';
import AnnounceKit from 'announcekit-react';

import UserLineIcon from 'remixicon-react/UserLineIcon';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

import { SessionUser } from '../../../types/SessionUser';
import DropdownContent from './NavbarDropDownContent';

import FilterDropdown from '../../atoms/Dropdown/FilterDropdown';
import useModal from '../../../services/hooks/useModal';
import { config } from '../../../config';
import { useAppState } from '../../../store/appstate';
import usePermissions from '../../../services/hooks/usePermissions';
import { SearchState } from '../../../store/search/searchSlice';
import WiggleAnimation from '../../atoms/Animations/Wiggle';
import useOnClickOutside from '../../atoms/Dropdown/useOnClickOutside';
import { hasWriteCreditAccount } from '../../../services/helpers/hasPermissions';
import Timer from '../../atoms/Timer';

interface NavbarProps {
    user?: SessionUser;
    theme: DefaultTheme;
}

interface ModalProps {
    isShown: boolean;
    onClick: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ user, theme }: NavbarProps) => {
    const { phrase } = useAppState<SearchState>(x => x.search);
    const { isShown, toggle } = useModal();
    const [showRefresh, setShowRefresh] = useState(true);

    const { hasRead } = usePermissions(['usermanagement-all-matchers']);

    const creditAccountWrite = hasWriteCreditAccount();

    window.onscroll = () => {
        if (window.pageYOffset !== 0) {
            setShowRefresh(false);
        } else {
            setShowRefresh(true);
        }
    };

    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClickOutside = () => {
        if (open) {
            setOpen(!open);
        }
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <Container isVisible={showRefresh}>
            <NavbarBackground>
                <NavBarContainer>
                    <MainNavbar>
                        <NavLinks>
                            <LogoLink to="/">
                                <WalleyLogo src="/img/walley-white-logo.png" alt="Walley" />
                            </LogoLink>
                            <NavItem to="/dashboard">Dashboard</NavItem>
                            <NavItem to={`/orders${phrase ? `?${phrase}` : ''}`}>Orders</NavItem>

                            <NavItem to="/paylinks?filter=status:%5B%22Pending%22,%22Completed%22,%22CustomerIdentified%22,%22CommittedToPayment%22%5D">
                                Pay Link
                            </NavItem>

                            <NavItem to="/reports">Reports</NavItem>
                            {creditAccountWrite && (
                                <NavAnchor href={config.WalleyCoroebus} target="_blank">
                                    Create credit account
                                </NavAnchor>
                            )}
                            {hasRead && <NavItem to="/marketing">Marketing</NavItem>}
                        </NavLinks>
                        <Filler />
                        {config &&
                            config.Environment &&
                            config.Environment.toLowerCase() !== 'prod' && (
                                <EnvironmentInfo
                                    isLocal={config.Environment.toLowerCase() === 'local'}
                                >
                                    <span title="This is a test environment">
                                        {config.Environment.toLowerCase() === 'uat'
                                            ? 'Test'
                                            : config.Environment}
                                    </span>
                                    <TimerWrapper>
                                        <Timer />
                                    </TimerWrapper>
                                </EnvironmentInfo>
                            )}

                        <NewUpdate>
                            <NewUpdateGiftIconWrapper className="announcekit-widget">
                                <AnnounceKit
                                    widget={`https://announcekit.co/widgets/v2/${config.AnnouncekitWidgetId}`}
                                >
                                    <GiftLineIcon size="22" />
                                </AnnounceKit>
                            </NewUpdateGiftIconWrapper>
                        </NewUpdate>

                        <UserDetails>
                            {user && (
                                <FilterDropdown
                                    isShown={isShown}
                                    toggle={toggle}
                                    initiator={
                                        <MenuContainer onClick={toggle} isShown={isShown}>
                                            <UserIcon>
                                                <UserLineIcon size={theme.icon.size.small} />
                                            </UserIcon>
                                            <UserMenu>{user.name}</UserMenu>

                                            <ChevronIcon>
                                                <ArrowDownSLineIcon size={theme.icon.size.small} />
                                            </ChevronIcon>
                                        </MenuContainer>
                                    }
                                >
                                    <DropdownContent toggle={toggle} />
                                </FilterDropdown>
                            )}
                        </UserDetails>
                    </MainNavbar>
                </NavBarContainer>
            </NavbarBackground>
        </Container>
    );
};

interface ContainerProps {
    isVisible: boolean;
}

const Container = styled.div<ContainerProps>`
    background: ${props => props.theme.colors.navBar.background};
    box-shadow: 0 0.1rem 0.2rem 0;
    top: 0;
    width: 100%;
    z-index: 4;
    position: ${props => (props.isVisible ? '' : 'sticky')};
    top: ${props => (props.isVisible ? '5rem' : 0)};
`;

const NavbarBackground = styled.div`
    background: ${props => props.theme.colors.navBar.background};
    box-shadow: 0 0.1rem 0.2rem 0;
    width: 100%;
    z-index: 4;
    width: 100%;
`;

const NewUpdate = styled.div`
    align-self: center;
    margin: 0.5rem 3rem 0 0;
`;

const NewUpdateGiftIconWrapper = styled.div`
    &:hover {
        animation: ${WiggleAnimation} 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    }
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
`;

const NavBarContainer = styled.div`
    padding: 0 2.5rem;
`;

const MainNavbar = styled.div`
    display: flex;
    height: 6rem;
    margin: 0 auto;
    color: ${props => props.theme.colors.navBar.foreground};
    max-width: 144rem;
    @media (max-width: 50rem) {
        max-width: 100rem;
    }
    transition: max-width 0.5s;
`;

const Filler = styled.div`
    flex: auto;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TimerWrapper = styled.span`
    color: #906925;
    display: none;
    cursor: default;
    animation: ${fadeIn} 0.5s ease-in-out;
`;

interface EnvironmentInfoProps {
    isLocal?: boolean;
}

const EnvironmentInfo = styled.div<EnvironmentInfoProps>`
    position: absolute;
    right: 0;
    color: ${props => props.theme.colors.black};
    background: ${props => props.theme.colors.accent3};
    width: 6rem;
    height: 2rem;
    padding-left: 0.5rem;
    text-align: center;
    border-bottom-left-radius: 2rem;
    z-index: 9999999;
    cursor: default;

    ${props =>
        props.isLocal &&
        `&:hover {
            display: flex;
            width: 30rem;
            transition: 1s;
            text-align: left;
            padding-left: 2rem;
            flex-direction: row;
            gap: 2rem;
        }

        &:hover ${TimerWrapper} {
            display: inline;
        }
    `}
`;

const UserDetails = styled.div`
    align-self: center;
`;

const NavLinks = styled.div`
    display: flex;
`;

const LogoLink = styled(Link)`
    display: inline-block;
    align-self: center;
    margin-right: 2.5rem;
`;

const WalleyLogo = styled.img`
    height: 2.5rem;
    margin-top: 0.3rem;
`;

const NavItem = styled(NavLink)`
    display: inline-block;
    padding: 0.6rem 1rem;
    margin: 0 1.5rem;
    text-decoration: none;
    color: ${props => props.theme.colors.navBar.foreground};
    align-self: center;
    font-family: ${props => props.theme.text.type.medium};
    font-size: ${props => props.theme.text.size.medium};
    &:hover {
        border-bottom: 0.2rem white solid;
        cursor: pointer;
        color: ${props => props.theme.colors.navBar.foreground};
    }
    &[aria-current] {
        border-bottom: 0.2rem white solid;
    }
`;

const NavAnchor = styled.a`
    display: inline-block;
    padding: 0.6rem 1rem;
    margin: 0 1.5rem;
    text-decoration: none;
    color: ${props => props.theme.colors.navBar.foreground};
    align-self: center;
    font-family: ${props => props.theme.text.type.medium};
    font-size: ${props => props.theme.text.size.medium};
    &:hover {
        border-bottom: 0.2rem white solid;
        cursor: pointer;
        color: ${props => props.theme.colors.navBar.foreground};
    }
    &[aria-current] {
        border-bottom: 0.2rem white solid;
    }
`;

const MenuContainer = styled.div<ModalProps>`
    display: flex;
    flex-wrap: nowrap;
    border-radius: 1.6rem;
    color: ${props => props.theme.colors.navBar.foreground};
    background: ${props =>
        props.isShown ? props.theme.colors.navBar.active : props.theme.colors.navBar.shineThrough};
`;

const UserIcon = styled.div`
    padding-top: 0.7rem;
    margin: 0 0.5rem 0 1.5rem;
`;

const ChevronIcon = styled.div`
    padding-top: 0.7rem;
    margin: 0 1.5rem 0 0;
`;

const UserMenu = styled.div`
    padding: 0.6rem 1.5rem 0.6rem 0.6rem;
    text-decoration: none;
    font-family: ${props => props.theme.text.type.medium};
    font-size: 1.4rem;
    color: ${props => props.theme.colors.navBar.foreground};
    white-space: nowrap;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.navBar.foreground};
    }
    &[aria-current] {
        color: ${props => props.theme.colors.navBar.foreground};
    }
`;



export default withTheme(Navbar) as React.ComponentType<Omit<NavbarProps, 'theme'>>;
