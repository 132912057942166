import { lighten } from 'polished';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface StyledProps {
    hideBorder?: boolean;
    color?: string;
    flex?: boolean;
    clickable?: boolean;
    width?: string;
    borderColor?: string;
}
export interface Props extends StyledProps {
    children: ReactNode;
    onClick?: () => void;
    dataTestId?: string;
}

const Card: React.FC<Props> = ({
    children,
    hideBorder,
    onClick,
    dataTestId,
    color,
    flex,
    width,
    borderColor,
}: Props) => {
    return (
        <Container
            clickable={onClick ? true : false}
            onClick={onClick}
            hideBorder={hideBorder}
            data-testid={dataTestId}
            color={color}
            flex={flex}
            width={width}
            borderColor={borderColor}
        >
            {children}
        </Container>
    );
};

const Container = styled.div<StyledProps>`
    ${props =>
        props.flex &&
        `
        display: flex;
        flex-grow: 1;
    `}
    ${props =>
        props.borderColor
            ? `border: 0.2rem solid ${props.borderColor};`
            : `border: 0.1rem solid ${props.theme.colors.border};`}
    border-radius: 0.5rem;
    border-bottom: ${props =>
        props.hideBorder
            ? `0`
            : !props.borderColor && `0.15rem solid ${props.theme.colors.card.border}`};
    background-color: ${props => (!props.color ? props.theme.colors.light : props.color)};
    overflow: hidden;
    ${props =>
        props.clickable &&
        `&:hover {
            cursor: pointer;

            background-color: ${
                !props.color ? props.theme.colors.light : lighten(0.1, props.color)
            };
        };`};
    ${props => props.width && `width: ${props.width}`};
`;

export default Card;


