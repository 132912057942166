/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import styled, { DefaultTheme, keyframes, withTheme } from 'styled-components';

interface SnackbarProps {
    message: string;
    color?: string;
    open: boolean;
    clear: any;
    theme: DefaultTheme;
}
const Snackbar: React.FC<SnackbarProps> = ({
    message,
    color,
    open,
    clear,
    theme,
}: SnackbarProps) => {
    useEffect(() => {
        const clearSnackbar = setTimeout(() => {
            clear();
        }, theme.timeout.veryLong);

        return () => {
            if (clearSnackbar) clearTimeout(clearSnackbar);
        };
    });

    return (
        <SnackbarContainer color={color} open={open}>
            {message}
        </SnackbarContainer>
    );
};

interface SnackbarContainerProps {
    color?: string;
    open: boolean;
}

const fadein = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

const fadeout = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
`;

const SnackbarContainer = styled.div<SnackbarContainerProps>`
    background-color: ${props => (props.color ? props.color : props.theme.colors.snackbar.normal)};
    position: fixed;
    left: 0;
    z-index: 6;
    padding: 2rem;
    margin: 2rem;
    width: 50rem;
    border-radius: 0.4rem;
    text-align: center;
    animation: ${fadein} 0.5s, ${fadeout} 0.5s 5.8s;
    bottom: 0;
`;



export default withTheme(Snackbar) as React.ComponentType<Omit<SnackbarProps, 'theme'>>;
