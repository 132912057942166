import React, { useEffect } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { formatNumber } from '../../../services/helpers/numberFormats';
import { useAppState } from '../../../store/appstate';
import { Order } from '../../../types/order/Order';
import OrderArticleStatus from '../../../types/order/OrderArticleStatus';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Spinner from '../../atoms/Spinner/Spinner';
import Status from '../../atoms/Status/Status';
import { LinkData } from '../../../types/response/LinkData';
import orderActions from '../../../store/order/actions';
import StatusTypes from '../../../types/StatusTypes';
import { ErrorType } from '../../../types/response/ErrorCodes';
import { PurchaseResponseData } from '../../../types/response/PurchaseResponseData';
import { OrderState } from '../../../store/order/orderSlice';
import SuccessMessage from '../../atoms/Message/Success/SuccessMessage';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface CancelOrderProps {
    order: Order;
    cancelLink?: LinkData;
    hide: () => void;
    theme: DefaultTheme;
}

const CancelOrder: React.FC<CancelOrderProps> = ({
    order,
    cancelLink,
    hide,
    theme,
}: CancelOrderProps) => {
    const dispatch = useAppDispatch();
    const { isCanceling, cancelSuccessful, error } = useAppState<OrderState>(s => s.order);
    const totalOrderValue = (order?.purchases ?? ([] as PurchaseResponseData[]))
        .flatMap(x => x.data.rows)
        .filter(r => r.status === OrderArticleStatus.NotActivated)
        .map(r => r.totalPrice || 0)
        .reduce((s, rowPrice) => s + rowPrice, 0);

    const formattedOrderValue = formatNumber(totalOrderValue);

    const onCancelOrder = () => {
        if (cancelLink) dispatch(orderActions.cancelOrder(cancelLink, order));
    };

    useEffect(() => {
        if (cancelSuccessful) hide();
    }, [cancelSuccessful, hide]);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Cancel articles</HeaderTitle>
                <OrderNumber>{order.orderNumber}</OrderNumber>
                <Status type={StatusTypes.Order} status={order.status} />
            </ModalContainer>
            <ModalContainer position="content">
                <MessageContainer>
                    <Block>This will cancel all non-activated items for this order</Block>

                    <Block>
                        Amount to cancel:
                        <StyledSpan>
                            {formattedOrderValue} {order.currency}
                        </StyledSpan>
                    </Block>

                    <Block>Do you want to proceed?</Block>
                </MessageContainer>

                {!isCanceling && cancelSuccessful && (
                    <SuccessMessage>All articles was cancelled!</SuccessMessage>
                )}
            </ModalContainer>

            {error && (
                <ModalContainer position="error">
                    <ErrorMessage error={error} errorHeader={ErrorType.CancelOrder} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button disabled={isCanceling} onClick={hide} large>
                        No
                    </Button>
                    <Button
                        tabIndex={0}
                        onClick={onCancelOrder}
                        primary
                        large
                        disabled={isCanceling || cancelSuccessful}
                    >
                        {isCanceling ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Yes</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const OrderNumber = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 2rem;
`;

const MessageContainer = styled.div`
    justify-content: center;
    max-width: 40rem;
    margin-right: ${props => props.theme.layout.padding.large};
`;

const StyledSpan = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin: 0.25rem;
    max-width: 100%;
`;

const Block = styled.div`
    margin-top: 1rem;
`;



export default withTheme(CancelOrder) as React.ComponentType<Omit<CancelOrderProps, 'theme'>>;
