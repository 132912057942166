import React from 'react';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import DndListItem from './DndListItem';

interface Props {
    list: string[];
    setList: (list: string[]) => void;
    checkboxOnChange: (name: string) => void;
    disableDrag?: boolean;
    dataTestId?: string;
}

const ChoosableDnDList: React.FC<Props> = ({
    list,
    setList,
    checkboxOnChange,
    disableDrag,
    dataTestId,
}: Props) => {
    const onDragEnd = ({ source, destination }: DropResult) => {
        if (destination === undefined || destination === null) return;
        if (destination.index === source.index) return;

        const newList = list.filter((_: string, idx: number) => idx !== source.index);

        newList.splice(destination.index, 0, list[source.index]);

        setList(newList);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="col-1">
                {provided => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        data-testid={dataTestId}
                    >
                        {list.map((text, index) => (
                            <DndListItem
                                onChange={checkboxOnChange}
                                key={text}
                                text={text}
                                index={index}
                                disableDrag={disableDrag}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};



export default ChoosableDnDList;
